
.sidebar-closed {
    display: none;
  }
  
  .sidebar-open {
    position: fixed;
    top: 56px;
    left: 0;
    bottom: 0;
    width: 250px;
    background: #f5f5f5;
    overflow-y: auto;
    z-index: 1000;
  }

  .nav-button{
    border: none;
    background-color: inherit;
    /* padding: 0 15px; */
  }

  .main-header{
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 8%;
  }
