.add-module{
    text-align: right;
}

.card-dummy{
    text-align: -webkit-center;
    padding: 10px, 15px;
    margin-top: 30px;

}
.card-icon{
    display: flex;
    justify-content: space-between;
}
.main-card{
    cursor: pointer;
    margin: 5px 0;
}
.main-card:hover{
    /* background-color: red; */
}

.theme-image-preview{
    width: 100px;
    max-width: 100px;
    height: 100px;
}

.theme-image-preview-remove:hover{
    color: red;
}

.full-screen-image {
    max-height: 62vh; 
    max-width: 80vw; 
    margin: 0 auto;
    display: block;
  }

  .solid-devider {
    border-top: 4px solid #000000;
    padding: 20px 0;
    margin-top: 35px;
}

.upload-button{
    width: 155px;
}

.action-button{
    background: inherit;
    padding: 5px 10px;
}

.noofpagetxt {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: var(--heading1);
    opacity: 0.84;
    margin-left: 11px;
  }
.paginationallignment {
    display: inline-block;
    float: right;
    /* display: flex; */
  justify-content: center;
  }
  .paginationsection {
    /* display: flex; */
    align-items: center;
    margin-top: 20px; /* Adjust as needed */
  }
   /* .paginationtxtholder {
    background-color: #f0f0f0; 
    padding: 8px 12px;
    border-radius: 5px;
    margin-right: 10px; 
  }  */
  .pagination > li {
    display: inline;
    margin-left: 6px;
  }

  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 2px;
    line-height: 1.42857143;
    text-decoration: none;
    margin-left: -1px;
    font-size: 16px;
    font-weight: 600;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    /* border: 1px solid #A9A9A9; */
    /* box-sizing: border-box; */
    border-radius: 4px;
    color: var(--heading1);
    font-weight: 400;
    /* margin-right: 50px; */
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    /* svg {
      path {
        fill: var(--heading1) !important;
      }
    } */
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    /* border: 1px solid #A9A9A9; */
    /* box-sizing: border-box; */
    border-radius: 4px;
    font-weight: 400;
    color: var(--textdark);
    /* margin-left: 50px; */
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    /* svg {
      path {
        fill: var(--heading1) !important;
      }
    } */
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    background:#ffffff;
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    box-shadow: 0px 1px 10px #A9A9A9;
    border-radius: 4px;
    cursor: pointer;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    /* border: 1px solid #A9A9A9; */
    box-sizing: border-box;
    border-radius: 4px;
    cursor: not-allowed;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }