.home-page-main{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 60vh;
    font-size: 2rem;
    font-weight: 700;
    /* font-family: monospace; */
    opacity: 0.2;
}

.home-page-heading{
    font-size: 2.3rem;
    margin-left: 4px;
    margin-right: 8px;
    opacity: 1;

}

@media screen and (max-width: 530px) {
    
    .home-page-main{
      
        font-size: 1.2rem;
    }
    .home-page-heading{
        font-size: 1.4rem;
      
    
    }
  }


  @media screen and (max-width: 308px) {
    
    .home-page-main{
      
        font-size: 12px;
    }
    .home-page-heading{
        font-size: 14px;
      
    
    }
  }