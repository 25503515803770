    #login{
        margin-top: 100px;
    }
    
    .card-body {
      text-align: left;
      padding: 20px 40px 40px;
    }
  
    .form-input {
      text-align: left;
      margin-bottom: 14px;
    }
  
    .login-card {
      max-width: 600px;
      margin: auto;
      border-radius: 6px;
      background: var(--white);
      box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
      border: none;
      margin-bottom: 50px;
    }
  
    .errorText {
      font-family: Poppins;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      text-align: center;
      margin-top: 20px;
      text-decoration: solid;
      color: red;
    }
  
    .brandlogo {
      width: 100px;
      margin: 0 auto;
    }
  
    .heading {
      font-size: 24px;
      font-weight: 500;
      margin-top: 35px;
      margin-bottom: 10px;
      text-align: center;
    }
  
    label {
      font-weight: 500;
      font-size: 20px;
      color: var(--navtext2);
      margin-bottom: 7px;
    }
  
    /* .CustomButton {
      text-align: center;
  
    .login-btn {
        margin: 15px 0;
      }
    } */
  
    .forgot-div {
      text-align: center;
  
  
    }
    .forgot-text {
      text-decoration: none;
    }
  
    .redirect-text {
      text-align: center;
      margin: auto;
      font-size: 13px;
      padding-top: 15px;
  
   
    }
       .login-signup {
        text-decoration: none;
      }
    @media screen and (max-width: 768px) {
      .card-body {
        padding: 20px 15px 40px;
      }
    }
  
  